<template>
  <div class="four">
    <h2>404</h2>
    <p>找不到页面拉</p>
    <router-link to="home">返回主页</router-link>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.four{
  margin:100px auto;
  font-size: 30px;
  h2{
    font-size: 40px;
  }
}
</style>
